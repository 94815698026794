<template>
  <div class="page">
    <div class="workspace">
      <div class="componentPicker">
        <el-select label="选择组件" v-model="currentComponent">
          <el-option v-for="(i, n) in componentList" :key="n" :label="i.name" :value="i.id"> </el-option>
        </el-select>
        <el-button @click="handleAddComponent">添加</el-button>
      </div>
      <div class="phoneViewContainer">
        <div class="phoneView">
          <template v-for="(i, n) in viewList">
            <component :is="i.component" :key="n"></component>
          </template>
        </div>
        <div class="appendAction">
          <el-button>上</el-button>
          <el-button>下</el-button>
          <el-button>-</el-button>
        </div>
      </div>
    </div>
    <div class="controlPanel"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data: () => ({
    componentList: [
      { id: 'adorn-swiper', name: '轮播图' },
      { id: 'adorn-centerMenu', name: '金刚区' }
    ],
    viewList: [{ component: 'adorn-swiper', value: '' }],
    currentComponent: ''
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    handleAddComponent() {
      if (!this.currentComponent) return
      this.viewList.push({ component: this.currentComponent, value: '' })
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
